import React from "react"
import { Link, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons"

const flashAnimation = keyframes`
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
`

const BlogHeader = styled.h4`
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 15px;

  svg {
    display: none;
    animation-name: ${flashAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  a {
    font-weight: 500;
    box-shadow: none;
    color: black;

    &:hover {
      svg {
        display: inline;
      }
    }
  }
`
const Date = styled.small`
  font-family: "Montserrat";
  font-weight: 500;
  text-transform: uppercase;
`

const Excerpt = styled.p`
  font-family: "Quicksand";
  font-weight: 300;
  font-size: 16px;
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article
            key={node.fields.slug}
            itemScope
            itemType="http://schema.org/Article"
          >
            <header>
              <BlogHeader>
                <Link
                  style={{ boxShadow: `none` }}
                  to={node.fields.slug}
                  itemProp="url"
                >
                  {title} <FontAwesomeIcon icon={faWindowMinimize} />
                </Link>
              </BlogHeader>
              <Date>{node.frontmatter.date}</Date>
            </header>
            <section>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
                itemProp="description"
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
